<template>
  <section 
    id="mobile-search-results" 
    class="find-a-camp">
    <div class="camp-list-container">
      <ul class="camp-list">
        <li><span v-html="resultsHeader"/> <a 
          v-if="!processing" 
          @click="resetSearch()">reset search</a></li>
        <li
          style="display:none;"
          v-if="processing"/>
        <li 
          v-else
          :key="camp.ID"
          v-for="camp in campsSorted">
          <div class="media">
            <template v-if="camp.logo">
              <figure class="media-left"><a 
                @click="toggleFullScreen(true)" 
                :href="camp.url"><img 
                  :src="camp.logo" 
                  :alt="camp.post_title"></a></figure>
            </template>
            <div class="media-content">
              <div class="content">
                <h2 class="entry-title"><a 
                  @click="toggleFullScreen(true)" 
                  :href="camp.url">{{ camp.post_title }}</a></h2>
                <ul class="camp-meta">
                  <li
                    v-for="(meta, i) in getMeta(camp)"
                    :key="i">{{ meta }}</li>
                </ul>
                <div class="entry-content">{{ getContent(camp) }}</div>
              </div>
              <nav class="level">
                <div class="level-right read-more-wrap">
                  <a 
                    @click="toggleFullScreen(true)"
                    class="read-more" 
                    :href="camp.url">See More ></a>
                </div>
              </nav>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <div class="search-buttons">
      <button class="button is-warning toggle-search">Open Search</button>
      <button class="button is-primary go-up">
        <i class="fas fa-arrow-up"/>
      </button>
    </div>
  </section>
</template>

<script>
  export default {
    data() {
      return {
        processing: true,
        noResults: false,
        campsSorted: {},
      }
    },
    computed: {
      resultsHeader: function () {
        if (this.noResults)
          return '<span class="icon"><i class="fas fa-warning"></i></span> No results found.';

        if (!this.processing && this.campsSorted && this.campsSorted.length)
          return 'Viewing ' + this.campsSorted.length + ' camps.';

        return '<span class="icon"><i class="fas fa-spin fa-spinner"></i></span>Fetching...';
      }
    },
    mounted() {

      // remove transform so go up can exist
      document.getElementById('app').style['will-change'] = "unset";

      Event.$on('campsReady', (camps) => {
        this.processing = true;
        setTimeout(() => {
        this.processing = false;
          this.campsSorted = camps;
        }, 1000);
      });
      Event.$on('noResults', (status) => {
        this.noResults = status;
      });
    },
    methods: {
      resetSearch() {
        Event.$emit('clearSearch');
        this.processing = true;
      },
      getContent(camp) {
        var trimmedContent = this.stripHTML(camp.post_content).substr(0, 150);
        trimmedContent = trimmedContent.substr(0, Math.min(trimmedContent.length, trimmedContent.lastIndexOf(" "))) + '...';
        return camp.post_excerpt.length ? this.stripHTML(camp.post_excerpt) : trimmedContent;
      },
      stripHTML(html) {
        var tmp = document.createElement("div");
        tmp.innerHTML = html;
        return tmp.textContent || tmp.innerText || "";
      },
      getMeta(camp) {
        var meta = [];
        meta.push(camp.categories.filter(function(cat) {
          return cat.taxonomy == 'camp-category';
        }).map(function(cat) {return cat.term.name}));
        meta.push(camp.categories.filter(function(cat) {
          return cat.taxonomy == 'camp-type';
        }).map(function(cat) {return cat.term.name}));
        meta.push(camp.categories.filter(function(cat) {
          return cat.taxonomy == 'session-length';
        }).map(function(cat) {return cat.term.name}));

        var string = meta
          .filter(function(entry) { return /\S/.test(entry); })
          .map(function(item) {
            return item.join(', ');
        });
        return string;
      },
    },
  }
</script>

<template>
  <div class="businesses">
    <div class="view-bar content">
        <div class="field">
            View:
            <p class="control has-icons-left">
                <span class="select">
                    <select v-model="view" @change="">
                        <option :value="i" v-for="(option,i) in options">{{option}}</option>
                    </select>
                </span>
                <span class="icon is-small is-left">
                    <i class="far fa-cog"></i>
                </span>
            </p>
        </div>
    </div>
    <div class="business-container">
        <div v-if="'byCat' == view" v-for="cat in categories">
            <h2 :id="sanitizeURL(cat)" class="business-cat">{{cat}}</h2>
            <div class="columns is-multiline content">
                <div class="column is-3" v-for="business in businessByCat(cat)">
                    <single-business v-on:openModal="openModal" :business="business" inline-template>
                        <a @click="openModal()" class="single-business model-popup" :href="'#' + sanitizeURL(business.name)">
                            <img v-if="business.logo" :alt="business.name" :src="business.logo">
                            <h3>{{business.name}}</h3>
                        </a>
                    </single-business>
                </div>
            </div>
        </div>
        <div v-if="'alpha' == view">
             <div class="columns is-multiline content">
                <div class="column is-3" v-for="business in businessSorted">
                    <single-business v-on:openModal="openModal" :business="business" inline-template>
                        <a @click="openModal()" class="single-business model-popup" :href="'#' + sanitizeURL(business.name)">
                            <img v-if="business.logo" :alt="business.name" :src="business.logo">
                            <h3>{{business.name}}</h3>
                        </a>
                    </single-business>
                </div>
            </div>
        </div>
    </div>
    <div v-if="modalData.hasOwnProperty('name')" :class="{'modal': true, 'is-active': showModal}">
        <div @click="closeModal()" class="modal-background"></div>
            <div class="modal-content">
                <div class="columns">
                    <div class="column logo-side">
                        <img v-if="modalData.logo" :alt="modalData.name" :src="modalData.logo">
                        <p class="page-link">View this listing in a new window: <a :href="modalData.link" target="_blank">{{modalData.link}}</a></p>
                    </div>
                    <section class="column content-side">
                        <h1>{{modalData.name}}</h1>
                        <p v-if="modalData.category.length" class="meta">
                            {{modalData.category.join(", ")}}
                        </p>
                        <div v-if="modalData.content" class="modal-desc" v-html="modalData.content"></div>
                        <div v-if="modalData.location" class="address">
                            <strong>Location</strong>
                            <div v-html="modalData.location"></div>
                        </div>
                    </section>
                </div>
                <ul class="contact-links">
                    <li v-if="modalData.email">
                        <i class="fas fa-envelope"></i>
                        <a :href="'mailto:'+modalData.email">
                            Email
                        </a>
                    </li>
                    <li v-if="modalData.phone">
                        <i class="fas fa-phone"></i>
                            {{modalData.phone}}
                    </li>
                    <li v-if="modalData.fax">
                        <i class="fas fa-fax"></i>
                            {{modalData.fax}}
                    </li>
                    <li v-if="modalData.website">
                        <a target="_blank" class="website" :href="addhttp(modalData.website)">
                            <i class="fas fa-globe"></i>
                            {{modalData.website}}
                        </a>
                    </li>
                </ul>
            </div>
            <button @click="closeModal()" class="modal-close is-large" aria-label="close"></button>
        </div>
  </div>
</template>

<script>
    export default {
      props: ['businesses', 'categories', 'startingView'],
      data() {
        return {
          businessSorted: [],
          view: 'byCat',
          options: {
              alpha: 'Alphabetical',
              byCat: 'By Category'
          },
          showModal: false,
          modalData: {}
        }
      },
      mounted() {
        this.businessSorted = this.sortAlpha();
        if (window.location.hash) {
            var hash = window.location.hash.substring(1);
            this.openModal(this.businesses.find(function(biz) {
                return (biz.name.replace(/[^a-z0-9]/gi, '-').toLowerCase() == hash);
            }));
        }
        document.addEventListener("keydown", (e) => {
            var key = event.keyCode ? event.keyCode : event.which;
            if (this.showModal && key == 27) {
                this.closeModal();
            }
        });
        if (this.startingView)
          this.view = this.startingView;

        this.$nextTick(() => {
          if (this.getTerm()) {
            var header = $('#' + this.getTerm());
            if (header.length) {
              $('html, body').animate({
                scrollTop: header.offset().top - 50
              }, 750);
            }
          }
        });
      },
      methods: {
        sortAlpha() {
            return this.businesses.sort(function(a, b) {
                var nameA = a.name.toUpperCase();
                var nameB = b.name.toUpperCase();
                if (nameA < nameB) {
                    return -1;
                }
                if (nameA > nameB) {
                    return 1;
                }
                return 0;
            });
        },
        openModal(data) {
            document.documentElement.style.overflow = "hidden";
            document.getElementById('app').style['will-change'] = "unset";
            document.getElementById('app').style['overflow-x'] = "unset";
            this.modalData = data;
            this.showModal = true;
        },
        closeModal() {
            document.documentElement.style.overflow = "unset";
            document.getElementById('app').style['will-change'] = "transform";
            document.getElementById('app').style['overflow-x'] = "hidden";
            this.removeHash();
            this.showModal = false,
            this.modalData = {}
        },
        businessByCat(cat) {
            return this.businessSorted.filter(function(item) {
                return item.category.includes(cat);
            });
        },
        removeHash() {
            history.pushState("", document.title, window.location.pathname + window.location.search);
        },
        addhttp(url) {
          if (!/^https?:\/\//i.test(url)) {
              url = 'http://' + url;
          }
          return url;
        },
        sanitizeURL(string) {
            return string.replace(/[|&;$%@"<>()+,]/g, "").replace(/[^a-z0-9]/gi, '-').replace('--', '-').toLowerCase()
        },
        getTerm() {
            var result = null,
                tmp = [];
            var items = location.search.substr(1).split("&");
            for (var index = 0; index < items.length; index++) {
                tmp = items[index].split("=");
                if (tmp[0] === 'term') result = decodeURIComponent(tmp[1]);
            }
            return result;
        }
      },
      components: {
          'single-business': {
              props: ['business'],
              data() {
                  return {
                      loading: true
                  }
              },
              mounted() {
                //   console.log(this.business);
              },
              methods: {
                  openModal() {
                    //   this.$parent.$options.methods.openModal(this.business);
                      this.$emit('openModal', this.business);
                  },
                  sanitizeURL(string) {
                      return string.replace(/[|&;$%@"<>()+,]/g, "").replace(/[^a-z0-9]/gi, '-').replace('--', '-').toLowerCase()
                  }
              }
          }
      }
    }
</script>

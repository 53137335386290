<template>
    <div class="profile widget_nav_menu">
        <h2 class="entry-title">Welcome, {{profile.display_name}}<button class="edit-button button is-small is-primary" @click="editProfile()"><i class="fas fa-btn fa-pencil"></i>edit</button></h2>
        <div :class="{'modal': true, 'is-active': showModal}">
            <div @click="closeModal()" class="modal-background"></div>
            <div class="modal-content section">
                <div class="field">
                    <label class="label">Display Name</label>
                    <p class="control has-icons-left">
                        <input v-model="profile.display_name" class="input" type="email" :placeholder="profile.display_name">
                        <span class="icon is-small is-left">
                            <i class="fas fa-user"></i>
                        </span>
                    </p>
                </div>
                <div class="field">
                    <label class="label">Email</label>
                    <p class="control has-icons-left">
                        <input v-model="profile.user_email" class="input" type="email" :placeholder="profile.user_email">
                        <span class="icon is-small is-left">
                            <i class="fas fa-envelope"></i>
                        </span>
                    </p>
                </div>
                <div class="field">
                    <label class="label">Password</label>
                    <p class="control has-icons-left">
                        <input v-model="profile.user_pass" class="input" type="password" placeholder="Password">
                        <span class="icon is-small is-left">
                        <i class="fas fa-lock"></i>
                        </span>
                    </p>
                    <p class="help is-info">Enter password here to change</p>
                </div>
                <button @click="updateUser()" :class="{'button': true, 'is-primary': true, 'is-loading': processing }">
                    Update
                </button>
                <span class="is-success" v-if="hasSuccess"><i class="fas fa-check"></i>Success</span>
                <span class="is-error" v-if="hasError"><i class="fas fa-times"></i>Error</span>
            </div>
            <button @click="closeModal()" class="modal-close is-large" aria-label="close"></button>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['user'],
        data() {
            return {
                profile: [],
                camps: {},
                showModal: false,
                processing: false,
                hasError: false,
                hasSuccess: false
            }
        },
        mounted() {
            delete this.user.data.user_pass;
            this.profile = this.user.data;
            // console.log(this.profile);
        },
        methods: {
            editProfile() {
                this.showModal = true;
                document.documentElement.style.overflow = "hidden";
            },
            closeModal() {
                document.documentElement.style.overflow = "auto";
                this.showModal = false;
            },
            updateUser() {
                this.processing = true;
                this.hasSuccess = false;
                this.hasError = false;
                axios.post('/slickfish/v1/member/update', {
                    data: this.profile 
                }).then(response => {
                    this.processing = false;
                    this.hasSuccess = true;
                }).catch(error => {
                    this.processing = false;
                    this.hasError = true;
                });
            }
        }
    }
</script>
